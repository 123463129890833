<template>
  <div class="date-picker-wrapper">
    <date-range-picker
      v-model="dateRange"
      ref="picker"
      opens="center"
      format="mm/dd/yyyy"
      show-dropdowns
      control-container-class="custom-date-picker"
      auto-apply
      :ranges="false"
      :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
      @select="onSelect"
    >
      <template #input="{startDate, endDate }">
        <span
          v-if="!startDate || !endDate"
          class="placeholder-text"
        >{{ placeholder }}</span>
        <span v-else-if="formatCalendarDate(startDate) === formatCalendarDate(endDate)">{{ formatCalendarDate(startDate) }}</span>
        <span v-else>{{ formatCalendarDate(startDate) }} to {{ formatCalendarDate(endDate) }}</span>
      </template>

      <!--      <template #ranges="{clickRange}">-->
      <!--        <div class="ranges">-->
      <!--          <ul>-->
      <!--            <li-->
      <!--              v-for="(range, index) in customRanges"-->
      <!--              :key="index"-->
      <!--              :class="{'is-actived': isSelected(range.value)}"-->
      <!--              @click="clickRange(range.value)"-->
      <!--            >-->
      <!--              {{ range.label }}-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--        </div>-->
      <!--      </template>-->
    </date-range-picker>

    <div class="wrapper-icon">
      <span
        v-if="dateRange.startDate"
        class="clear-icon"
      >
        <feather-icon
          icon="XIcon"
          size="14"
          @click="onClear"
        />
      </span>

      <span>
        <feather-icon
          icon="CalendarIcon"
          size="14"
        />
      </span>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import i18n from '@/libs/i18n'
// import moment from 'moment'

export default {
  components: {
    DateRangePicker,
  },

  mixins: [dateTimeFormatMixin],

  props: {
    value: {
      type: Object,
      required: true,
      default() {
        return {
          startDate: null,
          endDate: null,
        }
      },
    },

    placeholder: {
      type: String,
      default: i18n.t('partnerView.placeholderFilterByDate'),
    },
  },

  data() {
    return {
      dateRange: this.value,
    }
  },

  // computed: {
  //   customRanges() {
  //     const today = new Date()
  //     today.setHours(0, 0, 0, 0)
  //
  //     const yesterday = new Date()
  //     yesterday.setDate(today.getDate() - 1)
  //     yesterday.setHours(0, 0, 0, 0)
  //
  //     const last7DaysFrom = moment().subtract(7, 'd').toDate()
  //     const last7DaysTo = moment().subtract(1, 'd').toDate()
  //
  //     const last14DaysFrom = moment().subtract(14, 'd').toDate()
  //     const last14DaysTo = moment().subtract(1, 'd').toDate()
  //
  //     const last30DaysFrom = moment().subtract(30, 'd').toDate()
  //     const last30DaysTo = moment().subtract(1, 'd').toDate()
  //
  //     const thisWeek = moment().startOf('isoWeek').toDate()
  //
  //     const thisLastWeekFrom = moment().startOf('week').subtract(6, 'd').toDate()
  //     const thisLastWeekTo = moment().startOf('week').toDate()
  //
  //     const thisMonthFrom = moment().startOf('month').toDate()
  //
  //     const thisLastMonthFrom = moment().startOf('month').subtract(1, 'months').toDate()
  //     const thisLastMonthTo = moment().endOf('month').subtract(1, 'months').toDate()
  //
  //     // const veryFirstDate = moment('1970-1-1').toDate()
  //
  //     return [
  //       // {
  //       //   label: 'Lifetime',
  //       //   value: [veryFirstDate, today],
  //       // },
  //       {
  //         label: 'Today',
  //         value: [today, today],
  //       },
  //       {
  //         label: 'Yesterday',
  //         value: [yesterday, yesterday],
  //       },
  //       {
  //         label: 'Last 7 days',
  //         value: [last7DaysFrom, last7DaysTo],
  //       },
  //       {
  //         label: 'Last 14 days',
  //         value: [last14DaysFrom, last14DaysTo],
  //       },
  //       {
  //         label: 'Last 30 days',
  //         value: [last30DaysFrom, last30DaysTo],
  //       },
  //       {
  //         label: 'This week',
  //         value: [thisWeek, today],
  //       },
  //       {
  //         label: 'Last week',
  //         value: [thisLastWeekFrom, thisLastWeekTo],
  //       },
  //       {
  //         label: 'This month',
  //         value: [thisMonthFrom, today],
  //       },
  //       {
  //         label: 'Last month',
  //         value: [thisLastMonthFrom, thisLastMonthTo],
  //       },
  //     ]
  //   },
  // },

  methods: {
    isSelected(date) {
      return this.formatCalendarDate(this.dateRange.startDate) === this.formatCalendarDate(date[0])
    },

    onSelect({ startDate, endDate }) {
      if (this.formatCalendarDate(startDate) === this.formatCalendarDate(endDate)) {
        this.$emit('input', { startDate: this.formatCalendarDate(startDate), endDate: null })
        return
      }

      this.$emit('input', { startDate: this.formatCalendarDate(startDate), endDate: this.formatCalendarDate(endDate) })
    },

    onClear() {
      this.dateRange = {
        startDate: null,
        endDate: null,
      }
      this.$emit('input', { startDate: null, endDate: null })
    },
  },
}
</script>

<style lang="scss">
.date-picker-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 270px;
  min-height: 40px;
  padding: 7px 16px;
  border: 1px solid #e0e0e5;
  border-radius: var(--input-border-radius-base);
  width: 100%;

  .wrapper-icon {
    position: absolute;
    right: 13px;
    top: 7px;
    &:hover {
      cursor: pointer;
    }
  }

  .clear-icon {
    margin-right: 5px;
  }

  .vue-daterange-picker {
    width: 100%;
  }

  .calendars {
    flex-wrap: nowrap;
    .ranges {
      width: 100px;

      .is-actived {
        background-color: #eee;
      }
    }
  }
}

.custom-date-picker {
  &:hover {
    cursor: pointer;
  }

  .placeholder-text {
    color: #c0baba;
  }
}
</style>
